.card {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
}

.card img {
  width: 90%;
  height: 90%;
}
.card .cardFace {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--blueColor);
}
.card .cardFace.cardBackFace {
  transform: rotateY(180deg);
}
.isFlipped {
  transform: rotateY(180deg);
}
.isInactive {
  /* opacity: 0; */
  visibility: hidden;
}
