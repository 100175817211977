.greeting {
  padding: 0 10rem;
}

.greetingSecondParagraph {
  margin-bottom: 10rem;
}

.greetingButton {
  background-color: var(--whiteColor);
  width: 100px;
  height: 60px;
  border-color: var(--blueColor);
  border-radius: 0;
  color: var(--blueColor);
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
  margin-bottom: 16rem;
}

.greetingButtonLight {
  background-color: var(--darkColor);
}

.greetingButton:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: var(--blueColor);
  z-index: -1;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
}

.greetingButton:hover {
  color: #fff;
}

.greetingButton:hover:after {
  width: 110%;
}
