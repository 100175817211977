.navigationBar {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: var(--darkColor);
  margin: 0px;
  padding: 10px;
  font-family: "Roboto";
}
.nav {
  text-decoration: none;
  color: var(--whiteColor);
}
.navBarLogo {
  width: 50px;
  height: 50px;
}
.navBarLogo:hover {
  filter: brightness(0.5) sepia(1) hue-rotate(140deg) saturate(6);
}
