:root {
  --darkColor: #121212;
  --whiteColor: white;
  --blueColor: #08d;
  --greyColor: #9e9e9e;
}

body {
  /* height: 100vh; */
  /* padding: 0; */
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
ul {
  list-style-type: none;
}
