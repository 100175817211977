.error {
  color: red;
}
.firstName {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.firstName input {
  width: 15rem;
  height: 1.5rem;
  text-align: center;
}
.lastName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.lastName input {
  width: 15rem;
  height: 1.5rem;
  text-align: center;
}
.email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.email input {
  width: 15rem;
  height: 1.5rem;
  text-align: center;
}
.select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.selectDifficulty {
  width: 15.5rem;
  height: 2rem;
  /* margin-bottom: 3rem; */
}

option {
  text-align: center;
}

.suites {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
}
.suite {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.suite input {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin-top: 1rem;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.suite input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: var(--blueColor);
}

.suite input[type="radio"]:checked::before {
  transform: scale(1);
}

.submitButton {
  background-color: var(--blueColor);
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: var(--whiteColor);
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  outline: 0;
  text-align: center;
  width: 10%;
  margin-bottom: 15rem;
}

.submitButton:after {
  color: var(--greyColor);
}

.submitButton:hover {
  color: var(--darkColor);
}

.suiteImg {
  width: 100px;
  height: 100px;
}
