.toggle__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 5px;
}
.toggle__button {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-top: 6px;
  vertical-align: top;
  background: #ffffff;
  border: 1px solid #bbc1e1;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}
.toggle__button::after {
  content: "";

  display: inline-block;
  position: absolute;
  left: 3px;
  top: 1.5px;

  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);

  transform: translateX(0);
}
.toggle__button:checked::after {
  transform: translateX(calc(100% + 3px));
  background-color: #fff;
}
.toggle__button:checked {
  background-color: rgba(0, 0, 0, 0.6);
}
