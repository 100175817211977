.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modalContent {
  padding: 20px;
  border-radius: 5px;
  background-color: var(--whiteColor);
  width: 30vw;
  transform: scale(0.5);
  transition: 0.4s all;
}

.modalContent.active {
  transform: scale(1);
}

.modalContent.modalContentDark {
  background-color: var(--darkColor);
  color: var(--whiteColor);
}

.modalTitle {
  color: black;
  border-bottom: 1px solid gray;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.modalTitle.modalTitleDark {
  color: var(--whiteColor);
}

.modalBody {
  color: black;
  border-bottom: 1px solid gray;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.modalBody.modalBodyDark {
  color: var(--whiteColor);
}

.digits {
  color: black;
}

.digits.digitsDark {
  color: var(--whiteColor);
}

.modalButton {
  width: 100px;
  height: 45px;
  border-color: var(--blueColor);
  border-radius: 0;
  color: var(--blueColor);
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
}

.modalButton:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: var(--blueColor);
  z-index: -1;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
}

.modalButton:hover {
  color: #fff;
}

.modalButton:hover:after {
  width: 110%;
}
